<template>
  
  <div class="ls">
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div> 
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div> 
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div>
    <div class="rw skl"></div> 
    <div class="rw skl"></div> 
  </div>
 
</template>


<script setup>
 
</script>


<style scoped>

.ls{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}
.rw{  
  aspect-ratio: 230/400;
  width: calc(100% / 5 - 1rem);
  border-radius: 15px;
} 

 


@container pb (max-width: 1540px) {
  .rw{
    width: calc(100%/7 - 1rem);
  }
}

@container pb (max-width: 1340px) {
  .rw{
    width: calc(100%/6 - 1rem);
  }
}

@container pb (max-width: 1140px) {
  .rw{
    width: calc(100%/5 - 1rem);
  }
}

@container pb (max-width: 950px) {
  .rw{
    width: calc(100%/4 - 1rem);
  }
}

@container pb (max-width: 750px) {
  .rw{
    width: calc(100%/4 - 1rem);
  }
}

@container pb (max-width: 550px) {
  .rw{
    width: 150px;
  }
}


@container pb (max-width: 560px) { 
  .ls{
    justify-content: space-between;
  }

  .rw{ 
    width: calc(100%/2 - 0.5rem);
  }
}



</style>
